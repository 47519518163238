import "./modules/lightSlider";
import "./modules/burgerMenu";
import "./modules/favicon";

import objectFitImages from "object-fit-images"; // Import the objectFitImages module

let windowWidth = window.innerWidth; // reload page on resize

window.addEventListener("resize", function () {
  "use strict";
  if (window.innerWidth !== windowWidth) {
    windowWidth = window.innerWidth;
    window.location.reload();
  }
});

objectFitImages(); // init safari image fix
